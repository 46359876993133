import React from 'react';
import './MainTournament.scss';

function MainTournament({ children }) {
  return (
    
    <div className='maintournament'>
      {children}
    </div>
  )
}

export default MainTournament