import React from 'react';
import './FooterCOD4.scss';


function FooterCOD4() {
  return (
    <footer className="footercod4">
        <div className="footer-sectioncod4">
            <p>DESIGN PAR PIXETMOTION</p>
            <p>DÉVELOPPÉ PAR HELLFIRE © 2024</p>
        </div>
        <div className="footer-sectioncod4">
            <a href="https://twitch.tv/pixetmotion">
                <img src={require("../../../../assets/cod4/icons/icon_twitch.png")} alt="Twitch" />TWITCH.TV/PIXETMOTION
            </a>
        </div>
        <div className="social-mediacod4">
            <p>VIENS FOLLOW NOS RÉSEAUX</p>
            <a href="https://discord.gg/htVnjEqqtE"><img src={require("../../../../assets/cod4/icons/icon_discord.png")} alt="Discord" /></a>
            <a href="https://www.instagram.com/twitchpixetmotion/"><img src={require("../../../../assets/cod4/icons/icon_insta.png")} alt="Instagram" /></a>
            <a href="https://www.youtube.com/@pixetmotiongaming"><img src={require("../../../../assets/cod4/icons/icon_youtube.png")} alt="Youtube" /></a>
            <a href="https://twitter.com/pixetmotion"><img src={require("../../../../assets/cod4/icons/icon-twitter.png")} alt="Twitter" /></a>
            <a href="https://www.tiktok.com/@pixetmotion"><img src={require("../../../../assets/cod4/icons/icon_tiktok.png")} alt="Tiktok" /></a>
        </div>
    </footer>
  )
}
 
export default FooterCOD4;