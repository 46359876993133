import React from 'react';
import './RulesCOD4.scss';

function RulesCOD4() {
  return (
    <section className="rules-cod4">
          <img className="img-collapse1" src={require("../../../../assets/cod4/rules/RULES_COD4 (3).webp")} alt="Règlement Tournoi COD4" />
      
       
    </section>  
  );
}

export default RulesCOD4;
