import React from 'react';
import './CaserneCOD4.scss';

function CaserneCOD4() {
  return (
    <section className="caserne-mohaa">
        <iframe
            className='youtube1' 
            width="100%" 
            height="1080" 
            src="https://www.youtube.com/embed/SwGTxnimTSw?si=hY-WEOpIbwblhWau" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>    
        </iframe>
        <iframe
            className='youtube2' 
            width="100%" 
            height="720" 
            src="https://www.youtube.com/embed/SwGTxnimTSw?si=hY-WEOpIbwblhWau" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>    
        </iframe>
        <iframe
            className='youtube3' 
            width="100%" 
            height="400" 
            src="https://www.youtube.com/embed/SwGTxnimTSw?si=hY-WEOpIbwblhWau" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>    
        </iframe>

        {/* <img className='img-banner' src={require("../../../../assets/cod4/titles/NOS_SERVEURSCOD4.png")} alt="Bannière Serveurs" /> */}
        <div className='text-container'>
          <p className='text-map'> <strong>MAP LIST :</strong>  BACKLOT • CITY STREETS • VACANT • CROSSFIRE • STRIKE • CRASH</p>
        </div>

    </section>  
  )
}

export default CaserneCOD4