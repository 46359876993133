import React from 'react';
import './HeaderTournament.scss';
import Navbar from '../../../../components/Tournament/Default/NavBar/NavBar';
import Logo from '../../../../assets/logo/LOGO_PIX_TOURNAMENT.webp'
import { NavLink } from 'react-router-dom';



function HeaderTournament() {
  return (
    <header className='headertournament'>
      <NavLink to="/">
        <div>
              <img className='logo' src={Logo} alt='Logo Lespixionautes.fr' />
        </div>
      </NavLink>
      <Navbar />
      
    </header>
  )
}

export default HeaderTournament