import React, { useState, useEffect } from 'react';
import './RecruesCOD4.scss';

const RecruesCOD4 = () => {
  const [players, setPlayers] = useState([]);
  const [teams, setTeams] = useState({});

  const fetchData = async () => {
    try {
      const response = await fetch('https://dev.borezo.info/mohaa/data.php');
      const jsonData = await response.json();
      setPlayers(jsonData.players);
      setTeams(
        jsonData.teams.reduce((acc, team) => {
          acc[team.name] = team;
          return acc;
        }, {})
      );
    } catch (error) {
      console.error('Erreur lors de la récupération des données :', error);
    }
  };

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="fut-player-cardcod4">
      <img src={require("../../../../assets/cod4/titles/RECRUES.png")} alt='Banniere Recrues' className='img-bannercod4'/>
      {players.map((player, index) => (
        <div key={index} className="player-cardcod4">
          <img
            className="img-fut-player-cardcod4"
            src={require("../../../../assets/cod4/PLAYER_CARD_COD4.png")}
            alt=""
          />
          <div className="player-card-topcod4">
            <div className="player-master-infocod4">
              <div className="player-clubcod4">
                {teams[player.team] && (
                  <img src={teams[player.team].picture} alt={player.team} draggable="false" />
                )}
              </div>
            </div>
            <div className="player-picturecod4">
              <img src={player.picture} alt={player.pseudo} draggable="false" />
            </div>
          </div>
          <div className="player-card-bottomcod4">
            <div className="player-infocod4">
              <div className="player-namecod4">
                <span className="player-name-cod1cod4">{player.name}</span>
              </div>
              <div className="player-featurescod4">
                <div className="player-features-colcod4">
                  <span className="kills-feature-valuecod4">
                    <div className="player-feature-valuecod4">{player.kills}</div>
                    <div className="player-feature-titlecod4">Kills</div>
                  </span>
                  <span className="deaths-feature-valuecod4">
                    <div className="player-feature-valuecod4">{player.deaths}</div>
                    <div className="player-feature-titlecod4">Deaths</div>
                  </span>
                  <span className="ratio-feature-valuecod4">
                    <div className="player-feature-value-ratiocod4">{player.ratio}</div>
                    <div className="player-feature-titlecod4">Ratio</div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RecruesCOD4;
