import React from 'react';
import './HeaderCOD1.scss';
import NavbarCOD1 from '../../../../components/Tournament/COD1/NavBarCOD1/NavBarCOD1';
import { NavLink } from 'react-router-dom';

import BannerCOD1 from "../../../../assets/cod1/Background/Banniere_Menu_Cod1 (1).webp";


function HeaderCOD1() {
  return (
    <header className='header-cod1template'>
      <NavLink to="/">
        <div className='header-cod1'>
              <img className='banniere-cod1' src={BannerCOD1} alt='Banniere Logo Call Of Duty Tournament V1' />
        </div>
      </NavLink>
      <NavbarCOD1 />
      
    </header>
  )
}

export default HeaderCOD1