import React from 'react';
import './Match-MakingCOD1.scss';

function MatchCOD1() {
  return (
      <section class="phase-one">
        <div className='phase-header'>
            <h2>1<sup>ère</sup> PHASE</h2>
        </div>

        {/* MATCH 1 • ANIMA VS LES PTITS BITS */}
        <div className="match-making">
                <div className="team-making left-team"><img src={require("../../../../assets/cod1/player/anima_black.webp")} alt="Anima" className="logo-match" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Anima&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="vs-score-container">
                        <div className="score win" style={{backgroundColor: "green"}}>11</div>
                        <div className="vs">VS</div>
                        <div className="score lose" style={{backgroundColor: "red"}}>02</div>
                    </div>
                <div class="team-making right-team">Les P'tits Bits<img src={require("../../../../assets/cod1/player/lesptitsbits.webp")} alt="Les P'tits Bits" className="logo-match" /></div>
            </div>
            <a href="https://youtu.be/pXVJ_M2eBuM" className='match-making-rewind'>revoir le match</a>

            {/* MATCH 2 • LES GAULOIS VS NEMESIS */}
            <div className="match-making">
                <div className="team-making left-team"><img src={require("../../../../assets/cod1/player/lesgaulois_nobg.webp")} alt="Les Gaulois" className="logo-match" />&nbsp;&nbsp;Les Gaulois&nbsp;&nbsp;</div>
                    <div className="vs-score-container">
                        <div className="score win" style={{backgroundColor: "green"}}>11</div>
                        <div className="vs">VS</div>
                        <div className="score lose" style={{backgroundColor: "red"}}>02</div>
                    </div>
                <div class="team-making right-team">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nemesis&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src={require("../../../../assets/cod1/player/nemesis.webp")} alt="Nemesis" className="logo-match" /></div>
            </div>
            <a href="https://youtu.be/7FG5Gs6kCTg" className='match-making-rewind'>revoir le match</a>

            <div className='phase-header'>
              <h2>2<sup>ème</sup> PHASE</h2>
            </div>

            {/* MATCH 3 • LES PTITS BITS VS N0t1me */}
            <div className="match-making">
                <div className="team-making left-team"><img src={require("../../../../assets/cod1/player/lesptitsbits.webp")} alt="Les P'tits Bits" className="logo-match" />Les P'tits Bits</div>
                    <div className="vs-score-container">
                        <div className="score win" style={{backgroundColor: "red"}}>07</div>
                        <div className="vs">VS</div>
                        <div className="score lose" style={{backgroundColor: "green"}}>11</div>
                    </div>
                <div class="team-making right-team">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;N0t1me&nbsp;&nbsp;&nbsp;&nbsp;<img src={require("../../../../assets/cod1/player/notim_black.webp")} alt="N0t1me" className="logo-match" /></div>
            </div>
            <a href="https://youtu.be/a9H9Mu8guNE" className='match-making-rewind'>revoir le match</a>

            {/* MATCH 4 • ANIMA VS LES GAULOIS */}
            <div className="match-making">
                <div className="team-making left-team"><img src={require("../../../../assets/cod1/player/anima_black.webp")} alt="Anima" className="logo-match" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Anima&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="vs-score-container">
                        <div className="score win" style={{backgroundColor: "red"}}>00</div>
                        <div className="vs">VS</div>
                        <div className="score lose" style={{backgroundColor: "green"}}>11</div>
                    </div>
                <div class="team-making right-team">&nbsp;&nbsp;Les Gaulois&nbsp;&nbsp;<img src={require("../../../../assets/cod1/player/lesgaulois_nobg.webp")} alt="Les Gaulois" className="logo-match" /></div>
            </div>
            <a href="https://youtu.be/hv_qMc35nSQ" className='match-making-rewind'>revoir le match</a>

            <div className='phase-header'>
              <h2>3<sup>ème</sup> PHASE</h2>
            </div>

            {/* MATCH 5 • ANIMA VS NEMESIS */}
            <div className="match-making">
                <div className="team-making left-team"><img src={require("../../../../assets/cod1/player/anima_black.webp")} alt="Anima" className="logo-match" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Anima&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="vs-score-container">
                        <div className="score win" style={{backgroundColor: "red"}}>07</div>
                        <div className="vs">VS</div>
                        <div className="score lose" style={{backgroundColor: "green"}}>11</div>
                    </div>
                <div class="team-making right-team">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nemesis&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src={require("../../../../assets/cod1/player/nemesis.webp")} alt="Nemesis" className="logo-match" /></div>
            </div>
            <a href="https://youtu.be/BGCMvICwIlk" className='match-making-rewind'>revoir le match</a>

            {/* MATCH 6 • LES GAULOIS VS NOTIME */}
            <div className="match-making">
                <div className="team-making left-team"><img src={require("../../../../assets/cod1/player/lesgaulois_nobg.webp")} alt="Les Gaulois" className="logo-match" />&nbsp;&nbsp;Les Gaulois&nbsp;&nbsp;&nbsp;</div>
                    <div className="vs-score-container">
                        <div className="score win" style={{backgroundColor: "green"}}>11</div>
                        <div className="vs">VS</div>
                        <div className="score lose" style={{backgroundColor: "red"}}>06</div>
                    </div>
                <div class="team-making right-team">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NoT1me&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src={require("../../../../assets/cod1/player/notim_black.webp")} alt="N0t1me" className="logo-match" /></div>
            </div>
            <a href="https://youtu.be/zPs-0vcYVLg" className='match-making-rewind'>revoir le match</a>

            <div className='phase-header'>
              <h2>4<sup>ème</sup> PHASE</h2>
            </div>

            {/* MATCH 7 • LES PTITS BITS VS NEMESIS */}
            <div className="match-making">
                <div className="team-making left-team"><img src={require("../../../../assets/cod1/player/lesptitsbits.webp")} alt="Les P'tits Bits" className="logo-match" />Les P'tits Bits</div>
                    <div className="vs-score-container">
                        <div className="score win" style={{backgroundColor: "red"}}>00</div>
                        <div className="vs">VS</div>
                        <div className="score lose" style={{backgroundColor: "green"}}>11</div>
                    </div>
                <div class="team-making right-team">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nemesis&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src={require("../../../../assets/cod1/player/nemesis.webp")} alt="Nemesis" className="logo-match" /></div>
            </div>
            <a href="https://youtu.be/07R2PYGn5iA" className='match-making-rewind'>revoir le match</a>

            {/* MATCH 8 • ANIMA VS NOTIME */}
            <div className="match-making">
                <div className="team-making left-team"><img src={require("../../../../assets/cod1/player/anima_black.webp")} alt="Anima" className="logo-match" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Anima&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="vs-score-container">
                        <div className="score win" style={{backgroundColor: "orange"}}>10</div>
                        <div className="vs">VS</div>
                        <div className="score lose" style={{backgroundColor: "orange"}}>10</div>
                    </div>
                <div class="team-making right-team">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Not1me&nbsp;&nbsp;&nbsp;&nbsp;<img src={require("../../../../assets/cod1/player/notim_black.webp")} alt="N0t1me" className="logo-match" /></div>
            </div>
            <a href="https://youtu.be/FNUpPTNtZP0" className='match-making-rewind'>revoir le match</a>

            <div className='phase-header'>
              <h2>5<sup>ème</sup> PHASE</h2>
            </div>

            {/* MATCH 9 • LES GAULOIS VS LES PTITS BITS */}
            <div className="match-making">
                <div className="team-making left-team"><img src={require("../../../../assets/cod1/player/lesgaulois_nobg.webp")} alt="Les Gaulois" className="logo-match" />&nbsp;Les Gaulois&nbsp;&nbsp;&nbsp;</div>
                    <div className="vs-score-container">
                        <div className="score win" style={{backgroundColor: "green"}}>11</div>
                        <div className="vs">VS</div>
                        <div className="score lose" style={{backgroundColor: "red"}}>01</div>
                    </div>
                <div class="team-making right-team">Les P'tits Bits<img src={require("../../../../assets/cod1/player/lesptitsbits.webp")} alt="Les P'tits Bits" className="logo-match" /></div>
            </div>
            <a href="https://youtu.be/jVvazKMpffg" className='match-making-rewind'>revoir le match</a>

            {/* MATCH 10 • NEMESIS VS NOTIME */}
            <div className="match-making">
                <div className="team-making left-team"><img src={require("../../../../assets/cod1/player/nemesis.webp")} alt="Nemesis" className="logo-match" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nemesis&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="vs-score-container">
                        <div className="score win" style={{backgroundColor: "green"}}>11</div>
                        <div className="vs">VS</div>
                        <div className="score lose" style={{backgroundColor: "red"}}>05</div>
                    </div>
                <div class="team-making right-team">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NoT1me&nbsp;&nbsp;&nbsp;&nbsp;<img src={require("../../../../assets/cod1/player/notim_black.webp")} alt="N0t1ime" className="logo-match" /></div>
            </div>
            <a href="https://youtu.be/1iGvYTCkcfg" className='match-making-rewind'>revoir le match</a>
    </section>  
        
  )
}

export default MatchCOD1