import React from "react";
import { createBrowserRouter, Outlet } from "react-router-dom";

// Autres pages
import HomePage from "../pages/LesPixionautes/HomePage/HomePage";
import { ErrorPage } from "../pages/LesPixionautes/ErrorPage/ErrorPage";

// LAYOUT
import { Header, Main, Footer } from "../layout/LesPixionautes/";
import { HeaderTournament, MainTournament, FooterTournament } from "../layout/Tournament/Default";
import { HeaderCOD1, MainCOD1, FooterCOD1 } from "../layout/Tournament/COD1";
import { HeaderCOD4, MainCOD4, FooterCOD4} from "../layout/Tournament/COD4";
import MainOverlay from "../layout/Overlay/MainOverlay/MainOverlay";

//  Home Page Tournament
import HomePageTournament from "../pages/Tournament/HomePage";

// PAGE CALL OF DUTY 1
import TeamsCOD1 from "../pages/Tournament/COD1/TeamsCOD1/TeamsCOD1"
import MatchCOD1 from "../pages/Tournament/COD1/MatchCOD1/MatchCOD1";
import ClassementCOD1 from "../pages/Tournament/COD1/ClassementsCOD1/ClassementsCOD1";
import EliminatoireCOD1 from "../pages/Tournament/COD1/EliminatoireCOD1/EliminatoireCOD1";


// PAGE CALL OF DUTY 4
import CaserneCOD4 from "../pages/Tournament/COD4/CaserneCOD4/CaserneCOD4";
import RulesCOD4 from "../pages/Tournament/COD4/RulesCOD4/RulesCOD4";
import RecruesCOD4 from "../pages/Tournament/COD4/RecruesCOD4/RecruesCOD4";
import TeamsCOD4 from "../pages/Tournament/COD4/TeamsCOD4/TeamsCOD4";
import MatchCOD4 from "../pages/Tournament/COD4/MatchCOD4/MatchCOD4";
import ClassementCOD4 from "../pages/Tournament/COD4/ClassementCOD4/ClassementCOD4";


// PAGE OVERLAY
import NewsGaming from "../pages/Overlay/NewsGaming/NewsGaming";
import PlayerCardValo from "../pages/Overlay/PlayerCardValo/PlayerCardValo";

const HeaderFooterLayout = () => (
  <>
    <Header />
    <Main>
      <Outlet />
    </Main>
    <Footer />
  </>
);

const HeaderFooterLayoutTournament = () => (
  <>
    <HeaderTournament />
    <MainTournament>
      <Outlet />
    </MainTournament>
    <FooterTournament />
  </>
);

const HeaderFooterLayoutTournamentCOD1 = () => (
  <>
    <HeaderCOD1 />
      <MainCOD1>
          <Outlet />
      </MainCOD1>
    <FooterCOD1 />
  </>
);

const HeaderFooterLayoutTournamentCOD4 = () => (
  <>
    <HeaderCOD4 />
      <MainCOD4>
        <Outlet />
      </MainCOD4>
    <FooterCOD4 />
  </>
);

const HeaderFooterLayoutOverlay = () => (
  <>
    <MainOverlay>
      <Outlet />
    </MainOverlay>
  </>
);


export const router = createBrowserRouter([
  {
    element: <HeaderFooterLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
    ],
  },
  {
    element: <HeaderFooterLayoutTournament />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/tournament", element: <HomePageTournament />, },
      { path: "/work_in_progress_tu_n'en_s'aura_pas_plus", element: <HomePageTournament />,}
    ]
  },
  {
    element: <HeaderFooterLayoutTournamentCOD1 />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/tournament/cod1/teams", element: <TeamsCOD1 /> },
      { path: "/tournament/cod1/matchs",element: <MatchCOD1 /> },
      { path: "/tournament/cod1/classements", element: <ClassementCOD1 /> },
      { path: "/tournament/cod1/eliminatoires",element: <EliminatoireCOD1 /> }
    ]
  },
  {
    element: <HeaderFooterLayoutTournamentCOD4 />,
    errorElement: <ErrorPage />,
    children: [
      {path: "/tournament/cod4/caserne",element: <CaserneCOD4 /> },
      {path: "/tournament/cod4/rules",element: <RulesCOD4 /> },
      {path: "/tournament/cod4/recrues",element: <RecruesCOD4 /> },
      { path: "/tournament/cod4/teams", element: <TeamsCOD4 /> },
      { path: "/tournament/cod4/matchs", element: <MatchCOD4 /> },
      {path: "/tournament/cod4/classements", element: <ClassementCOD4 /> }
    ]
  },
  {
    element: <HeaderFooterLayoutOverlay />,
    errorElement: <ErrorPage />,
    children: [
      { path:"/overlay/newsgaming",  element: <NewsGaming />, },
      { path: "/overlay/playerscards", element: <PlayerCardValo />, }
    ]
  }
]);
