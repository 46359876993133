import React from 'react';
import './MatchCOD1.scss';
import Match from "../../../../components/Tournament/COD1/Match-MakingCOD1/Match-MakingCOD1"

function MatchCOD1() {
  return (
      <section className="section-matchmaking">
        <img src={require("../../../../assets/cod1/Background/banniere_match.webp")} alt="Match Making" className='banner_matchmaking'/>
        <Match />
    </section>  
        
  )
}

export default MatchCOD1