import React from 'react';
import './PlayerCardCOD1.scss'; // Importation du fichier de style

const logoplayers = [
	["logo_skz", require("../../../../../assets/cod1/player/skz_2642.webp")],
	["logo_skypjeh", require("../../../../../assets/cod1/player/skypjeh.webp")],
	["logo_apart", require("../../../../../assets/cod1/player/apart0707.webp")],
	["logo_canaa", require("../../../../../assets/cod1/player/canaa.webp")],
	["logo_nemo", require("../../../../../assets/cod1/player/nemo473.webp")],
	["logo_neus", require("../../../../../assets/cod1/player/leneus_1.webp")],
	["logo_wysix", require("../../../../../assets/cod1/player/wysix.webp")],
	["logo_dividotgif", require("../../../../../assets/cod1/player/dividotgif.webp")],
	["logo_alexander", require("../../../../../assets/cod1/player/alexandersv.webp")],
	["logo_legumen", require("../../../../../assets/cod1/player/logo_legumen.png")],
	["logo_twic", require("../../../../../assets/cod1/player/twic_ender.webp")],
	["logo_mbanania", require("../../../../../assets/cod1/player/mbanania.webp")],
	["logo_tugaa", require("../../../../../assets/cod1/player/tugaalm.webp")],
	["logo_mr6ril", require("../../../../../assets/cod1/player/mr_6ril.webp")],
	["logo_medattak", require("../../../../../assets/cod1/player/medattak.webp")],
	["logo_nikoalbara", require("../../../../../assets/cod1/player/nikoalbara.webp")],
	["logo_cyril", require("../../../../../assets/cod1/player/wawcyrillamouette.webp")],
	["logo_flammes256", require("../../../../../assets/cod1/player/flamme256.webp")],
	["logo_anthrakys", require("../../../../../assets/cod1/player/anthrakys.webp")],
	["logo_naked", require("../../../../../assets/cod1/player/lms764.webp")],
	["logo_misteracet", require("../../../../../assets/cod1/player/misteracet.webp")],
	["logo_jayjay", require("../../../../../assets/cod1/player/jayjay75013.webp")],
	["logo_themen", require("../../../../../assets/cod1/player/ro78.webp")],
	["logo_franc3sc0", require("../../../../../assets/cod1/player/franc3sc0_14.webp")],
	["logo_xianno", require("../../../../../assets/cod1/player/xian_no.webp")],
]

const logoteam = [
	["logo_anima", require("../../../../../assets/cod1/player/ANIMA_LOGO.webp")],
	["logo_lesptitsbits", require("../../../../../assets/cod1/player/lesptitsbits.webp")],
	["logo_gaulois", require("../../../../../assets/cod1/player/lesgaulois_nobg.webp")],
	["logo_nemesis", require("../../../../../assets/cod1/player/nemesis.webp")],
	["logo_not1me", require("../../../../../assets/cod1/player/Notime.webp")],
  ];


const PlayerCard = ({ player, sortCriteria }) => {

		const logoplayersPath = logoplayers.find(item => item[0] === player.logo);
		const logoPath = logoteam.find(item => item[0] === player.teamlogo);

  return (
    <div className="fut-player-card">
      <img className='img-fut-player-card' src={require("../../../../../assets/halloffame/PLAYER_CARD_HALL_OF_FAME.webp")} alt="" />
	<div className="player-card-top">
		<div className="player-master-info">
			<div className="player-club">
				<img src={logoPath ? logoPath[1] : ''} alt={player.team} draggable="false"/>
			</div>
		</div>
		<div className="player-picture">
       <img src={logoplayersPath ? logoplayersPath[1] : ''} alt={player.pseudo} draggable="false" />
		</div>
	</div>
	<div className="player-card-bottom">
		<div className="player-info">
			<div className="player-name">
				<span className='player-name-cod1'>{player.pseudo}</span>
			</div>
			<div className="player-features">
				<div className="player-features-col">
					<span className='kills-feature-value'>
						<div className="player-feature-value">{player.kills}</div>
						<div className="player-feature-title">Kills</div>
					</span>
					<span className='deaths-feature-value'>
						<div className="player-feature-value">{player.deaths}</div>
						<div className="player-feature-title">Deaths</div>
					</span>
					<span className='ratio-feature-value'>
						<div className="player-feature-value-ratio">{player.ratio}</div>
						<div className="player-feature-title">Ratio</div>
					</span>
				</div>
			</div>
		</div>
	</div>
</div>
  );
};

export default PlayerCard;
