import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './HomePage.scss';
import HallOfFameCOD1 from '../../components/Tournament/HallOfFame/COD1/HallOfFameCOD1';

function HomePage() {
  const [selectedGame, setSelectedGame] = useState('COD4');

  // Configuration du slider sans les boutons
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 15000,
    arrows: false, // Supprime les boutons du slider
  };

  return (
    <div className="homepage dark-mode">
      {/* Section des actualités avec un slider */}
      <section className="news-section">
      <h2>Actualités</h2>
      <Slider {...sliderSettings}>
        <div className="news-item">
          <h3>Résultats Tournoi <br /> Call Of Duty 4 !</h3>
          <p>
            Le tournoi Call of Duty 4 s'est terminé en beauté avec une compétition intense <br /> 
            entre les meilleures équipes. Après plusieurs manches très disputées, <br />
            l'équipe <strong>WEMAKENOISE</strong> a su s'imposer grâce à une stratégie impeccable <br />
            et des performances individuelles exceptionnelles. Bravo à eux pour cette <br />
            victoire méritée qui restera dans les annales !<br />
          </p>
        </div>
        
        <div className="news-item">
          <h3>Résultats Tournoi <br /> Call Of Duty 1</h3>
          <p>
            Le tournoi de Call of Duty 1 a rassemblé les vétérans de la série, et l'équipe <br />
            <strong>LES GAULOIS</strong> a montré qu'elle maîtrisait encore parfaitement les fondamentaux <br />
            du jeu. En remportant ce tournoi avec un score final impressionnant, ils confirment <br />
            leur statut de légende dans la communauté. Félicitations à eux pour leur <br />
            persévérance et leur cohésion !<br />
          </p>
        </div>
        
        <div className="news-item">
          <h3>Préparation <br /> d'un futur tournoi...</h3>
          <p>
            Une nouvelle compétition se prépare dans l'univers des Pixionautes ! L'équipe vous <br />
            réserve un événement spécial qui promet d'être encore plus excitant. Préparez-vous à <br />
            relever des défis inédits et à affronter des équipes du monde entier. Plus de détails <br />
            sur ce tournoi seront communiqués d'ici le mois de Novembre, alors restez à l'affût <br />
            des annonces !<br />
          </p>
        </div>
        
        <div className="news-item">
          <h3>Mise à Jour <br /> Site Web</h3>
          <p>
            Le site web des Pixionautes a fait peau neuve ! Une refonte complète a été réalisée <br />
            pour améliorer l'expérience utilisateur et rendre la navigation plus fluide et intuitive. <br />
            Que vous soyez joueur, spectateur ou simple visiteur, vous profiterez désormais d'un <br />
            site plus rapide et adapté aux dernières technologies. Venez découvrir les nouveautés <br />
            sur <strong>lespixionautes.fr/tournament</strong> !
          </p>
        </div>
      </Slider>

      </section>

      {/* Section Hall of Fame */}
      <section className="hall-of-fame">
        <h2>Hall of Fame</h2>
        <div className="game-selector">
          <button className={selectedGame === 'COD4' ? 'active' : ''} onClick={() => setSelectedGame('COD4')}>
            Call of Duty 4
          </button>
          <button className={selectedGame === 'COD1' ? 'active' : ''} onClick={() => setSelectedGame('COD1')}>
            Call of Duty 1
          </button>
        </div>
        <div className="game-stats">
          {selectedGame === 'COD1' && (
            <HallOfFameCOD1 />
          )}
          {selectedGame === 'COD4' && (
            <ul>
              <li><strong>Work In Progress :</strong> Le Hall Of Fame COD4 est en cours de construction</li>
            </ul>
          )}
        </div>
      </section>
    </div>
  );
}

export default HomePage;
