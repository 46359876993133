import React from 'react';
import './MatchCOD4.scss';
import MatchMakingCOD4 from "../../../../components/Tournament/COD4/MatchMakingCOD4/MatchMakingCOD4";

function MatchCOD4() {


  return (
    <section className="match-cod4">
      <MatchMakingCOD4 />

    </section>
  );
}

export default MatchCOD4;
