import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons';
import './NavBarCOD1.scss';

function NavbarCOD1() {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {/* Navbar pour toutes tailles d'écran */}
      <nav className={`navbarcod1 ${isMobile ? 'navbarcod1-mobile' : 'navbarcod1-desktop-nav'}`}>
        <ul className="navbarcod1-nav-links">
          <li>
            <NavLink to="/tournament/cod1/teams">TEAMS</NavLink>
          </li>
          <li>
            <NavLink to="/tournament/cod1/matchs">MATCH</NavLink>
          </li>
          <li>
            <NavLink to="/tournament/cod1/classements">CLASSEMENT</NavLink>
          </li>
          <li>
            <NavLink to="/tournament/cod1/eliminatoires">ÉLIMINATOIRE</NavLink>
          </li>
          <li>
            <NavLink to="/tournament">
              <FontAwesomeIcon icon={faArrowRightToBracket} />
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default NavbarCOD1;
