import React from 'react';
import './HeaderCOD4.scss';
import NavbarCOD4 from '../../../../components/Tournament/COD4/NavbarCOD4/NavbarCOD4';
import { NavLink } from 'react-router-dom';



function HeaderCOD4() {
  return (
    <header className='header-cod4'>
      <NavLink to="/">
        <div className='img-cod4'>
              <img className='banniere-cod4' src={require('../../../../assets/cod4/header/BANNIERE_SITE_COD4_TOURNAMENT.webp')} alt='Banniere Logo Call Of Duty 4' />
        </div>
      </NavLink>
      <NavbarCOD4 />
      
    </header>
  )
}

export default HeaderCOD4