import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons';

import './NavBar.scss';

// Fonction pour générer une chaîne aléatoire de caractères
function getRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'; // Lettres et chiffres
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

function Navbar() {
  const [randomValorantName, setRandomValorantName] = useState(getRandomString(8)); // État pour le nom aléatoire

  const handleMouseEnter = () => {
    setRandomValorantName(getRandomString(8)); // Met à jour le nom aléatoire à chaque survol
  };

  return (
    <nav className='navbardefault'>
      <NavLink to="/">
        ACCUEIL
      </NavLink>
      <div className='separator'>|</div>
      <NavLink to="/work_in_progress_tu_n'en_s'aura_pas_plus"  onMouseEnter={handleMouseEnter}>
        {randomValorantName} {/* Affichage de la chaîne aléatoire ici */}
      </NavLink>
      <div className='separator'>|</div>
      <NavLink to="/tournament/cod4/caserne">
        CALL OF DUTY 4
      </NavLink>
      <div className='separator'>|</div>
      <NavLink to="/tournament/cod1/teams">
        CALL OF DUTY 1
      </NavLink>
      <div className='separator'>|</div>
      <NavLink to="/">
        <FontAwesomeIcon icon={faArrowRightToBracket} />
      </NavLink>
    </nav>
  );
}

export default Navbar;
