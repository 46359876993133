import React from 'react';
import './MainCOD4.scss';

function MainCOD4({ children }) {
  return (
    
    <div className='main-cod4'>
      {children}
    </div>
  )
}

export default MainCOD4;