import React, { useState, useEffect } from 'react';
import TeamCard from './TeamCardCOD1/TeamCardCOD1';
import PlayerCard from './PlayerCardCOD1/PlayerCardCOD1';
import dataTeams from '../../../../data/COD1/datateams.json';
import dataPlayers from '../../../../data/COD1/dataplayers.json';
import './HallOfFameCOD1.scss'; // Ton fichier de style spécifique

const HallOfFameCOD1 = () => {
  const [teamsData] = useState(dataTeams);
  const [playersData, setPlayersData] = useState(dataPlayers);
  const [sortCriteria, setSortCriteria] = useState({ field: 'kills', order: 'desc' });

  useEffect(() => {
    // Tri des joueurs en fonction des critères sélectionnés
    const sortedPlayers = [...playersData].sort((a, b) => {
      if (sortCriteria.order === 'asc') {
        return a[sortCriteria.field] - b[sortCriteria.field];
      } else {
        return b[sortCriteria.field] - a[sortCriteria.field];
      }
    });
    setPlayersData(sortedPlayers);
  }, [sortCriteria]);

  const handleSort = (field) => {
    // Alterne entre tri ascendant et descendant
    const order = sortCriteria.field === field && sortCriteria.order === 'desc' ? 'asc' : 'desc';
    setSortCriteria({ field, order });
  };

  return (
    <div className="hall-of-fame-cod1">
      <div className="team-cards">
        {teamsData.map((team, index) => (
          <TeamCard key={index} team={team} />
        ))}
      </div>

      <h2 className="section-title classement-playercod1">Classement des joueurs</h2>
      <div className="player-controls">
        <button className="sort-button" onClick={() => handleSort('kills')}>TRIER PAR KILLS</button>
        <button className="sort-button" onClick={() => handleSort('deaths')}>TRIER PAR DEATHS</button>
        <button className="sort-button" onClick={() => handleSort('ratio')}>TRIER PAR RATIO</button>
      </div>

      <div className="player-cards">
        {playersData.map((player, index) => (
          <PlayerCard key={index} player={player} sortCriteria={sortCriteria} />
        ))}
      </div>
    </div>
  );
};

export default HallOfFameCOD1;
