import React from 'react';
import './ClassementsCOD1.scss';
import PointsCOD1 from '../../../../components/Tournament/COD1/PointsCOD1/PointsCOD1';
import GoalAverageCOD1 from '../../../../components/Tournament/COD1/GoalAverageCOD1/GoalAverageCOD1';

function ClassementCOD1() {
  return (
    <section className='cod1-classements'>
        <img src={require("../../../../assets/cod1/Background/classement_banner.webp")} alt="Infos Serveur" className='banner'/>
        <PointsCOD1 />
        <GoalAverageCOD1 />
    </section>
  );
}

export default ClassementCOD1
