import React from 'react';
import './TeamCardCOD1.scss'; // Importation du fichier de style

const logoteam = [
	["logo_anima", require("../../../../../assets/cod1/player/ANIMA_LOGO.webp")],
	["logo_lesptitsbits", require("../../../../../assets/cod1/player/lesptitsbits.webp")],
	["logo_gaulois", require("../../../../../assets/cod1/player/lesgaulois_nobg.webp")],
	["logo_nemesis", require("../../../../../assets/cod1/player/nemesis.webp")],
	["logo_not1me", require("../../../../../assets/cod1/player/Notime.webp")],
];

const TeamCard = ({ team }) => {
  // Vérifier si team.logo est égal à l'un des logos définis dans logoteam
  const logoPath = logoteam.find(item => item[0] === team.logo);

  return (
    <div className="team-card">
      <img src={logoPath ? logoPath[1] : ''} className='picture-teams' alt={team.teams} />
      <h3>{team.teams}</h3>
      <p>Kills: {team.killteams}</p>
      <p>Deaths: {team.deathteams}</p>
      <p>Ratio: {team.ratioteams}</p>
      <h4>Players:</h4>
      <ul className='ul-players'>
        {Object.values(team.players).map((player, index) => (
          <li className='li_players' key={index}>{player}</li>
        ))}
      </ul>
    </div>
  );
};

export default TeamCard;
